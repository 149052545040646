import './App.css';
import telegram from './Images/telegram.png';
import dex from './Images/dex.png';
import twitter from './Images/twitter.png';
import banner from './Images/book of tate.png';

function App() {
  return (
    <div className="App">
      <header className="Header">
        BOOK OF TATE
      </header>
      <div className="Images">
        <img src={banner} alt="Description of image 1" />
      </div>
      <div className="Socials-Container">
        CA: 6cKjwemEvHyAvVXB4sYRwnmpJQQGNb2bCExkf6y1pump
      </div>
      <div className="Socials-Container">
        <div className="Socials">
        <a href="https://x.com/BookOfTateOnSol" target="_blank" rel="noopener noreferrer">
          <img src={twitter}/>
        </a>
        <a href="https://t.me/bookoftate" target="_blank" rel="noopener noreferrer">
          <img src={telegram}/>
        </a>
        <a href="https://pump.fun/6cKjwemEvHyAvVXB4sYRwnmpJQQGNb2bCExkf6y1pump" target="_blank" rel="noopener noreferrer">
          <img src={dex}/>
        </a>
        </div>
      </div>
    </div>
  );
}

export default App;
